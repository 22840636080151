<template>
  <header
    class="the-header header-container"
    :class="{ 'show-sales-notice': showSalesNotice }"
    :style="`padding-bottom: ${salesNoticeHeight}px`"
  >
    <div class="fixed top-0 left-0 z-40 w-full bg-white header-content shadow-menu">
      <div
        class="py-4 bg-white lg:py-6"
        :class="{ 'lg:pb-0': !isMyEnv }"
      >
        <component :is="headerContent" />
      </div>
      <transition name="sales-notice-transition">
        <top-bar-notice
          v-if="showSalesNotice"
          class="-z-1"
          @dismiss="dismissSaleNotice"
        >
          Nu stapelkorting! Bij 2 producten 5% korting, bij 3 producten 10% en bij 4 of meer 15% korting!
        </top-bar-notice>
      </transition>
    </div>
  </header>
</template>

<script>
  import Cookies from 'js-cookie'
  import { computed, nextTick, onBeforeUnmount, onMounted, ref, useRoute, watch } from '@nuxtjs/composition-api'
  import TopBarNotice from './TopBarNotice.vue'

  const WebsiteHeaderContent = () => import(/* webpackPrefetch: true */ '@/components/WebsiteHeaderContent.vue')
  const AccountHeaderContent = () => import(/* webpackPrefetch: true */ '@/components/AccountHeaderContent.vue')

  export default {
    components: {
      TopBarNotice
    },
    setup () {
      const route = useRoute()

      const salesNoticeHeight = ref(0)

      const hasCookieSet = ref(true)

      const isEcommercePage = computed(() => {
        const ecommerceNamespaces = [
          'winkelwagen',
          'afrekenen',
          'producten'
        ]
        const currentRoute = route.value.path.split('/')
        const currentNamespace = currentRoute[0] || currentRoute[1]
        return !!ecommerceNamespaces?.includes(currentNamespace)
      })

      const showSalesNotice = computed(() => isEcommercePage.value && !hasCookieSet.value)

      const isMyEnv = computed(() => route.value.path.includes('/mijnexamenbundel'))

      const headerContent = computed(() => {
        return isMyEnv.value ? AccountHeaderContent : WebsiteHeaderContent
      })

      const dismissSaleNotice = () => {
        Cookies.set('sale_notice_cookie', true, { expires: 365, path: '/' })
        hasCookieSet.value = true
        const dismissSalesNoticeEvent = new CustomEvent('dismiss_sale_notice')
        document.dispatchEvent(dismissSalesNoticeEvent)
        salesNoticeHeight.value = 0
      }

      const updateSalesNoticeHeight = () => {
        nextTick(() => {
          salesNoticeHeight.value = showSalesNotice.value ? (document.getElementsByClassName('sales-notice')[0]?.offsetHeight ?? 0) : 0
        })
      }

      onMounted(() => {
        hasCookieSet.value = Cookies.get('sale_notice_cookie')
        window.addEventListener('resize', updateSalesNoticeHeight)
        updateSalesNoticeHeight()
      })

      watch(isEcommercePage, (currentValue) => {
        if (currentValue) updateSalesNoticeHeight()
      })

      onBeforeUnmount(() => {
        window.removeEventListener('resize', updateSalesNoticeHeight)
      })

      return {
        headerContent,
        isMyEnv,
        salesNoticeHeight,
        showSalesNotice,
        dismissSaleNotice
      }
    }
  }
</script>

<style lang="scss">
  .the-header {
    @apply transition-all;
    box-sizing: content-box;
    height: 5rem;
    @screen lg {
      height: 6rem;
    }

    .q-svg-examenbundel-logo {
      height: 2rem;
      width: auto;
      display: block;
      @screen md {
        width: 7.0625rem;
        max-width: 113px;
        height: auto;
        margin-top: .25rem;
      }
    }
    .q-svg-mijn-examenbundel-logo {
      width: 10.125rem;
    }
    .header-content {
      transition: transform .25s ease(easeOutSine);
    }
  }

  .sales-notice-transition {
    &-enter-active, &-leave-active {
      transition: opacity .15s ease(easeOutSine), transform .2s ease(easeOutQuad);
    }
    &-enter, &-leave-to {
      opacity: 0;
      transform: translateY(-0.5rem);
    }
  }
</style>
