export const state = () => ({
  userChatbots: [],
  activeSubjectChatbot: {}
});

export const mutations = {
  SET_CHATBOTS (state, data) {
    state.userChatbots = data;
  },
  SET_ACTIVE_SUBJECT_CHATBOT (state, data) {
    state.activeSubjectChatbot = data;
  }
};

export const getters = {
  getUserChatbots (state) {
    return state.userChatbots;
  },
  getActivatedUserChatbots (state) {
    return state.userChatbots.filter(chatbot => chatbot.isActive === true);
  },
  getActiveSubjectChatbot (state) {
    return state.activeSubjectChatbot;
  }
};

export const actions = {
  async getChatbots ({ state, commit }) {
    try {
      const { data } = await this.$axios.get(
        '/v1/users/me/chatbots'
      );
      commit('SET_CHATBOTS', data);
    } catch (e) {
      console.error(e);
    }
  },
  async getChatbotById ({ state, commit }, id) {
    try {
      const { data } = await this.$axios.get(
        `/v1/users/me/chatbots/${String(id)}`
      );
      commit('SET_ACTIVE_SUBJECT_CHATBOT', data);
    } catch (e) {
      console.error(e);
    }
  }
};

